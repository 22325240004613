<template>
  <div class="card border-0 shadow mb-4">
    <div class="table-responsive mb-0">
      <table class="table table-hover">
        <thead class="bg-brown--light">
          <tr>
            <th
              class="text-center text-nowrap"
              v-for="(header, idx) in headers"
              :key="header.value"
            >
              <slot
                v-if="hasScopedSlot('title-transform-' + header.value)"
                :name="'title-transform-' + header.value"
                :header="header"
                :value="header.text || ''"
                :index="idx"
              />
              <span v-else>
                {{ header.text }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="isLoading">
            <tr v-for="n of 10" :key="n" class="animate-pulse">
              <td v-for="header in headers" :key="header.value">
                <div
                  class="align-middle text-center text-nowrap py-1 rounded-pill"
                  style="background-color: rgb(229, 231, 235)"
                >
                  <span style="visibility: hidden">{{ header.text }}</span>
                </div>
              </td>
            </tr>
          </template>
          <tr v-else-if="!items.length">
            <td
              :colspan="headers.length"
              class="align-middle py-4 text-left text-nowrap"
            >
              沒有找到任何結果
            </td>
          </tr>
          <template v-else>
            <tr v-for="(item, idx) of items" :key="idx">
              <td
                :class="[`${header.value}-item-data`,'align-middle text-center', header.class ? header.class:'text-nowrap']"
                v-for="header in headers"
                :key="header.value"
              >
                <slot
                  v-if="hasScopedSlot('custom-' + header.value)"
                  :name="'custom-' + header.value"
                  :item="item"
                  :index="idx"
                  :value="item[header.value]"
                />
                <template v-else>
                  <span v-if="item.hasOwnProperty(header.value)">
                    {{ item[header.value] || '-' }}
                  </span>
                </template>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TableData',
  data: () => ({
    currentPage: 1
  }),
  props: {
    items: {
      type: Array,
      default: () => []
    },
    headers: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    hasScopedSlot (name) {
      return !!this.$scopedSlots[name]
    }
  },
  computed: {}
}
</script>
