<script>
export default {
  name: 'Pagination',
  data: () => ({
    currentPage: 1,
    maxVisiblePages: 6
  }),
  props: {
    listData: {
      required: true
    },
    limit: {
      required: false,
      default: 10
    },
    value: {
      required: false,
      default: 1
    }
  },
  watch: {
    localValue (newValue) {
      this.currentPage = newValue
    },
    immediate: true
  },
  methods: {
    nextPage () {
      if (this.currentPage < this.totalPages) {
        this.currentPage++
        this.$emit('input', this.currentPage)
      }
    },
    prevPage () {
      if (this.currentPage > 1) {
        this.currentPage--
        this.$emit('input', this.currentPage)
      }
    },
    handlePaginate (pageNo) {
      this.currentPage = pageNo
      this.$emit('input', this.currentPage)
    },
    jumpForward () {
      const jumpTo = this.currentPage + this.maxVisiblePages
      this.handlePaginate(jumpTo > this.totalPages ? this.totalPages : jumpTo)
    },
    jumpBackward () {
      const jumpTo = this.currentPage - this.maxVisiblePages
      this.handlePaginate(jumpTo < 1 ? 1 : jumpTo)
    }
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    totalPages () {
      return Math.ceil(this.listData.length / this.limit)
    },
    paginatedData () {
      return this.listData.slice(
        (this.currentPage - 1) * this.limit,
        this.currentPage * this.limit
      )
    },
    visiblePages () {
      let rangeStart = this.currentPage - Math.floor(this.maxVisiblePages / 2)
      rangeStart = rangeStart < 1 ? 1 : rangeStart
      let rangeEnd = rangeStart + this.maxVisiblePages - 1
      rangeEnd = rangeEnd > this.totalPages ? this.totalPages : rangeEnd
      rangeStart = rangeEnd - this.maxVisiblePages + 1
      rangeStart = rangeStart < 1 ? 1 : rangeStart

      return Array.from(
        { length: rangeEnd - rangeStart + 1 },
        (_, i) => i + rangeStart
      )
    }
  }
}
</script>

<template>
  <nav aria-label="Categories pagination">
    <ul class="pagination">
      <li class="page-item" :class="{ disabled: currentPage === 1 }">
        <a v-on:click.prevent="prevPage()" class="page-link" href="#"
          > 以前的 </a
        >
      </li>
      <li v-if="visiblePages[0] > 1" class="page-item">
        <a v-on:click.prevent="jumpBackward()" class="page-link" href="#"
          >...</a
        >
      </li>
      <li
        v-for="pageNo in visiblePages"
        :key="pageNo"
        :class="{ active: pageNo === currentPage }"
        class="page-item"
      >
        <a
          v-on:click.prevent="handlePaginate(pageNo)"
          class="page-link"
          href="#"
          >{{ pageNo }}</a
        >
      </li>
      <li
        v-if="visiblePages[visiblePages.length - 1] < totalPages"
        class="page-item"
      >
        <a v-on:click.prevent="jumpForward()" class="page-link" href="#">...</a>
      </li>
      <li class="page-item" :class="{ disabled: currentPage === totalPages }">
        <a v-on:click.prevent="nextPage()" class="page-link" href="#"> 下一頁 </a>
      </li>
    </ul>
  </nav>
</template>
