<template>
  <b-container fluid>
    <nav class="d-flex align-items-center mb-3 mb-lg-4" aria-label="breadcrumb">
      <router-link
        class="text-brown text-decoration-none mr-3"
        to="/user-management"
      >
        <svg
          class="mr-3"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_15_368)">
            <path
              d="M31.866 29.046C31.916 28.71 32.975 20.636 28.227 15.08C25.31 11.666 20.809 9.79703 14.997 9.61203L14.966 3.99903C14.966 3.61303 14.743 3.26203 14.395 3.09603C14.046 2.93303 13.633 2.98003 13.334 3.22403L0.366998 13.866C0.134999 14.056 -1.52259e-06 14.34 -0.00100152 14.64C-0.00200152 14.94 0.131998 15.224 0.363998 15.414L13.332 26.157C13.63 26.404 14.047 26.455 14.393 26.289C14.744 26.124 14.966 25.771 14.966 25.385L14.997 19.646C29.006 19.636 30.002 28.612 30.036 28.983C30.079 29.487 30.398 29.88 30.904 29.896C30.916 29.897 30.927 29.897 30.938 29.897C31.433 29.897 31.792 29.536 31.866 29.046ZM13.261 17.922C13.073 18.11 12.967 18.364 12.967 18.63V23.268L2.578 14.646L12.967 6.10403V10.597C12.967 11.15 13.414 11.597 13.967 11.597C19.657 11.597 24.004 13.245 26.702 16.373C28.731 18.727 29.664 21.608 29.983 23.999C27.799 21.015 23.975 17.636 13.968 17.63C13.968 17.63 13.967 17.63 13.966 17.63C13.702 17.63 13.448 17.735 13.261 17.922Z"
              fill="#6B5045"
            />
          </g>
          <defs>
            <clipPath id="clip0_15_368">
              <rect width="32" height="32" fill="white" />
            </clipPath>
          </defs>
        </svg>
        返回
      </router-link>
      <ol class="breadcrumb bg-transparent p-0 mb-0">
        <li class="breadcrumb-item">
          <router-link to="/">主頁</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/user-management">使用者管理</router-link>
        </li>
        <li class="breadcrumb-item">優惠券</li>
      </ol>
    </nav>
    <b-row class="mb-4">
      <b-col cols="12" xl="4" md="6" class="mb-2">
        <div class="input-group">
          <span class="input-group-text input-group-text--addon-left">
            <b-icon icon="search"></b-icon>
          </span>
          <input
            v-model="searchQuery"
            type="text"
            class="form-control form-control--addon-left form-control--h46px pl-48px mw-324px rounded-10px"
            placeholder="按標題搜尋"
          />
        </div>
      </b-col>
    </b-row>
    <TableData :items="paginatedData" :headers="headers" :isLoading="isLoading">
      <template v-slot:custom-imageUrl="{ item }">
        <img
          class="rounded-circle"
          width="66"
          height="66"
          v-bind:src="item.imageURL"
        />
      </template>
      <template v-slot:custom-createdDate="{ item }">
        {{ item.createdDate | formatDate }}
      </template>
      <template v-slot:custom-assignedDate="{ item }">
        {{ item.assignedDate | formatDate }}
      </template>
      <template v-slot:custom-expiryDate="{ item }">
        {{ item.expiryDate | formatDate }}
      </template>
      <template v-slot:custom-couponStatus="{ item }">
        <div v-if="item.couponStatus === 'ACTIVE'" class="text-success">
          啟用
        </div>
        <div v-else-if="item.couponStatus === 'INACTIVE'" class="text-danger">
          不活跃的
        </div>
        <div v-else-if="item.couponStatus === 'USED'" class="text-danger">
          已使用
        </div>
        <div v-else class="text-danger">已過期</div>
      </template>
      <template v-slot:custom-action="{ item }">
        <b-dropdown
          variant="link"
          toggle-class="text-white text-decoration-none"
          no-caret
        >
          <template #button-content>
            <b-icon
              icon="three-dots"
              font-scale="1.5"
              class="text-dark"
            ></b-icon>
          </template>
          <b-dropdown-item
            v-on:click="invalidateCouponForCustomer(item.couponId)"
            class="text-danger"
            >反駁優惠券</b-dropdown-item
          >
        </b-dropdown>
      </template>
    </TableData>
    <Pagination
      v-if="filterCoupons.length && !isLoading"
      v-model="currentPage"
      :list-data="filterCoupons"
      :limit="limit"
    />
  </b-container>
</template>

<script>
import couponService from '../../services/coupon.service'
import Pagination from '../../components/Pagination.vue'
import TableData from '../../components/TableData.vue'

export default {
  name: 'LoyaltyPrograms',
  data: () => ({
    customerCoupons: [],
    currentPage: 1,
    limit: 10,
    searchQuery: '',
    headers: [
      { text: '優惠券編號', value: 'id' },
      { text: '相片', value: 'imageUrl' },
      { text: '標題', value: 'titleEn' },
      { text: '標題 (繁)', value: 'titleZh' },
      { text: '提供', value: 'couponDiscountPercentage' },
      { text: '有效期限天數', value: 'validityPeriodDays' },
      { text: '狀態', value: 'couponStatus' },
      { text: '創建日期', value: 'createdDate' },
      { text: '提供日期', value: 'assignedDate' },
      { text: '優惠券到期日', value: 'expiryDate' },
      { text: '操作', value: 'action' }
    ],
    isLoading: true
  }),
  components: {
    Pagination,
    TableData
  },
  mounted () {
    this.getAllCustomersCoupons()
  },
  computed: {
    filterCoupons () {
      return this.customerCoupons.filter((item) =>
        this.searchQuery.length
          ? item?.titleEn
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(this.searchQuery.toLowerCase().replace(/\s+/g, '')) ||
            item?.titleZh
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(this.searchQuery.toLowerCase().replace(/\s+/g, ''))
          : true
      )
    },
    paginatedData () {
      return this.filterCoupons.slice(
        (this.currentPage - 1) * this.limit,
        this.currentPage * this.limit
      )
    }
  },
  watch: {
    filterCoupons () {
      this.currentPage = 1
    }
  },
  methods: {
    async getAllCustomersCoupons () {
      this.isLoading = true
      await couponService.getAllCustomersCoupons(this.$route.params.id).then(
        (res) => {
          this.customerCoupons = res.data.map((item) => ({
            ...item,
            ...item.couponViewDTO
          }))
          console.log(this.customerCoupons)
        },
        (err) => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
          this.isLoading = false
        }
      )
      this.isLoading = false
    },
    invalidateCouponForCustomer (couponId) {
      couponService
        .invalidateCouponForCustomer(couponId, this.$route.params.id)
        .then((res) => {
          if (res.success) {
            this.makeToast('success', '優惠券', '優惠券失效')
          } else {
            this.makeToast('danger', '優惠券', res.message)
          }
          this.getAllCustomersCoupons()
        })
        .catch((err) => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        })
    },
    makeToast (variant = null, title = 'Title', message = 'Message!') {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 3000,
        variant: variant,
        solid: true
      })
    }
  }
}
</script>
