import http from './http-common'

class CouponService {
  async getAllCoupons () {
    return (await http.get('/staff/coupon/all')).data
  }

  async createCoupon (json) {
    return (await http.post('/staff/coupon/create-coupon', json)).data
  }

  async uploadCouponImage (data) {
    return (await http({
      method: 'POST',
      url: '/staff/coupon/upload-image',
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data;boundary = ' + new Date().getTime()
      }
    })).data.data
  }

  async assignCustomerToCoupon (couponId, customerId, isBirthdayCoupon) {
    return (await http.post(`/staff/coupon/${couponId}/assign-customer-to-coupon?isBirthdayCoupon=${isBirthdayCoupon}&customerId=${customerId}`)).data
  }

  async invalidateCouponForCustomer (couponId, customerId) {
    return (await http.put(`/staff/coupon/${couponId}/invalidate-for-customer?customerId=${customerId}`)).data
  }

  async getAllCustomersCoupons (customerId) {
    return (await http.get(`/staff/coupon/get-coupons-for-customer/${customerId}`)).data
  }

  async assignCouponToAll (couponId, isBirthdayCoupon) {
    return (await http.post(`/staff/coupon/${couponId}/assign-to-all-customers?isBirthdayCoupon=${isBirthdayCoupon}`)).data
  }

  async getCouponById (couponId) {
    return (await http.get(`/staff/coupon-by-id/${couponId}`)).data.data
  }

  async expireCoupon (couponId) {
    return (await http.put(`/staff/coupon/${couponId}/expire-coupon`)).data
  }

  async assignToSelectedCustomers (couponId, customerIds, isBirthdayCoupon) {
    const queryParams = customerIds.map((item) => `customerIds=${item}`).join('&')
    return (await http.post(`/staff/coupon/${couponId}/assign-to-list-of-customers?${queryParams}&isBirthdayCoupon=${isBirthdayCoupon}`)).data
  }

  async getCouponHistory (couponId) {
    return (await http.get(`/staff/coupon/${couponId}/get-all-customers`)).data.data
  }

  async getCustomerUsingCouponById (couponId) {
    return (await http.get(`/staff/coupon/${couponId}/get-all-customers-for-used-coupon`)).data.data
  }
}

export default new CouponService()
